// extracted by mini-css-extract-plugin
export var desktop_bg = "index-module--desktop_bg--0401b";
export var desktop_br = "index-module--desktop_br--e93d8";
export var general_info = "index-module--general_info--87d0e";
export var hero = "index-module--hero--8a08f";
export var item = "index-module--item--5c419";
export var items_wrapper = "index-module--items_wrapper--a2620";
export var mobile_bg = "index-module--mobile_bg--320ed";
export var offer = "index-module--offer--a19f2";
export var search_styles = "index-module--search_styles--fbc5b";
export var subtitle = "index-module--subtitle--f7d23";