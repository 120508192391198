import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import MainLayout from '@hoc/layout';
import Seo from '@components/seo/seo';
import SearchFilters from '@components/search-filters/search-filters';
import OpinionsSection from '@components/opinions-section/opinions-section';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import AboutSection from '@components/about-section/about-section';
import PromoSection from '@components/promo-section/promo-section';
import BlogPostsSection from '@components/blog-posts-section/blog-posts-section';

import * as s from './index.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';

import shield_ico from '@assets/images/icons/shield.svg';
import key_ico from '@assets/images/icons/key.svg';
import money_ico from '@assets/images/icons/money.svg';

const Home = () => {

  const data = useStaticQuery(graphql`
    query {
      metaImg: file(relativePath: { eq: "images/metaImg.jpg" }) {
        publicURL
      }
      heroMobileBg: file(relativePath: { eq: "images/hero/mobile_bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      heroDesktopBg: file(relativePath: { eq: "images/hero/desktop_bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);


  return (
    <MainLayout>

      <Seo
        title='Leasing i wynajem samochodów, maszyn i urządzeń'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s.hero} ${s_wrapper.apply} ${s_wrapper.apply_padding}`}>

        <h1>Co możemy dla Ciebie <br className={s.desktop_br} /> sfinansować?</h1>
        <p className={`${s_text.subtitle} ${s.subtitle}`}>
	        Możemy Ci pomóc w uzyskaniu finasowania nie tylko na samochód osobowy czy<br/>
	        dostawczy  ale na dowolne maszyny i urządzenia niezbędne dla Twojego biznesu.<br/>
	        Jeżeli szukasz najkorzystniejszej oferty dla siebie to jesteś w dobrym miejscu!
		</p>

        <GatsbyImage image={getImage(data.heroMobileBg)} alt='zdjęcie w tle' className={s.mobile_bg} />
        <GatsbyImage image={getImage(data.heroDesktopBg)} alt='zdjęcie w tle' className={s.desktop_bg} />

        <SearchFilters classes={s.search_styles} redirect_mode={true} dark_mode={true} />

      </section>


      <PromoSection />


      <section className={`${s.offer} ${s_wrapper.apply} ${s_wrapper.apply_low_top_height}`}>

        <h2>Oferta</h2>
        <p className={`${s_text.subtitle} ${s.subtitle}`}>
	        Oferujemy leasing, wynajem długoterminowy, pożyczki i  kredyty dla firm i osób fizycznych.
        </p>

        <p className={s.general_info}>
          Dla naszych klientów przygotowaliśmy szeroką ofertę finansowania. Do Twoich potrzeb dopasujemy parametry
          leasingu, pożyczki, wynajmu długoterminowego czy kredytu samochodowego. Dbamy również o to, abyś na każdym etapie
          współpracy i spłaty zobowiązania miał zapewnione wsparcie ekspertów. Poznaj naszą pełną ofertę i skontaktuj
          się z nami, by wybrać najkorzystniejszą dla siebie formę finansowania.
        </p>

        <div className={s.items_wrapper}>

          <div className={s.item}>

            <img src={shield_ico} alt='Tarcza' />
            <h3>Ubezpieczenia OC AC GAP</h3>
            <p>
              Każdy posiadacz pojazdu, bez względu na formę jego finansowania,
              powinien wykupić obowiązkowe ubezpieczenie komunikacyjne,
              pozwalające na legalne oraz bezpieczne przemieszczanie się
              na terenie kraju, jak i poza jego granicami.
            </p>

          </div>

          <div className={s.item}>

            <img src={key_ico} alt='Klucz' />
            <h3>Wynajem długoterminowy</h3>
            <p>
              Leasing All Inclusive to wynajem długoterminowy określany również jako leasing CFM,
              Full Service Leasing czy auto na abonament. Jest to forma finansowania, która
              aktualnie cieszy się wśród firm największym zainteresowaniem.
            </p>

          </div>

          <div className={s.item}>

            <img src={money_ico} alt='Pieniądze' />
            <h3>Kredyty samochodowe<br/>Dla firm i osób fizycznych</h3>
            <p>
              Cieszący się ogromną popularnością leasing nie jest oczywiście jedyną
              możliwością rozpoczęcia użytkowania wymarzonego auta. W wielu sytuacjach
              korzystniejszą opcją okazuje się kredyt samochodowy.
            </p>

          </div>

        </div>

      </section>


      <OpinionsSection />

      <BlogPostsSection />

      <AboutSection />

      <ContactSection />

      <MapSection />

    </MainLayout>
  );

}

export default Home;
