import React, { useRef, useEffect, useState } from 'react';
import Slick from 'react-slick';
import { Helmet } from 'react-helmet';

import HandleLoading from '@hoc/handle-loading/handle-loading';

import * as s from './opinions-section.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import '@assets/styles/slider_opinions.scss';

//import fb from '@assets/images/icons/facebook.svg';
import star from '@assets/images/icons/star.svg';


const mockupData = [
    {
        stars: 5,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    },
    {
        stars: 4,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    },
    {
        stars: 4,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    },
    {
        stars: 5,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    },
    {
        stars: 4,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    },
    {
        stars: 5,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    },
    {
        stars: 5,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    },
    {
        stars: 4,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    },
    {
        stars: 5,
        title: 'Nullam gravida lacus in turpis porta!',
        content: 'Donec tempor eget quam eget finibus. Quisque eget sem congue dolor eleifend dignissim sed fringilla massa. Etiam luctus feugiat finibus.',
        name: 'John Doe'
    }
]

const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        }
    ]
}

const google_maps_key = 'AIzaSyDPRIwvaIorWwfcPOAqtWHjC40SAmatimg';

const OpinionsSection = () => {

    const mapRef = useRef(null);
    const [opinions, setOpinions] = useState([]);
    const [error, setError] = useState(false);


    useEffect(() => {

        if(window.google === undefined || window.google.maps.places === undefined){

            let google_api = document.createElement("script");
            google_api.src = `https://maps.googleapis.com/maps/api/js?key=${google_maps_key}&libraries=places&language=pl`;
            google_api.id = "google-opinions-api";
            google_api.async = true;
            google_api.defer = true;
            google_api.addEventListener('load', () => {
                initOpinions();
            });

            document.body.appendChild(google_api);
        } else {
            initOpinions();
        }

    }, []);

    const initOpinions = () => {

        const map = new window.google.maps.Map(mapRef.current, {
            zoom: 14,
            center: { lat: 50.299430, lng: 18.681740 },
            disableDefaultUI: true
        });

        let service = new window.google.maps.places.PlacesService(map);

        const request = {
            placeId: 'ChIJPw0KiKQxEUcRW7h3Ou-ULAQ',
            fields: ['reviews']
        };

        service.getDetails(request, (place, status) => {
            if (status == window.google.maps.places.PlacesServiceStatus.OK) {
                setOpinions(place.reviews);
            } else {
                setError(true);
            }
        });
    }


    return (
        <>

            <div className={s.map} ref={mapRef}></div>

            <section id='referencje' className={`${s.opinions_section} opinions_section ${s_wrapper.apply} ${s_wrapper.apply_padding} ${s_wrapper.apply_height}`}>

                <h2>Opinie</h2>

                <HandleLoading loading={opinions.length === 0} error={error}>

                    <Slick {...settings} className={s.slider_wrapper}>

                        {opinions.map((el, id) => (
                            <div key={id} className={s.slide_outer}>
                                <div className={s.slide}>
                                    <div className={s.img_wrapper}>
                                        <img src={el.profile_photo_url} alt='Zdjęcie profilowe' />
                                    </div>

                                    <div className={s.stars_wrapper}>
                                        {[...Array(el.rating)].map((_, id) => (
                                            <img src={star} key={id} alt='Ikona gwiazdki' />
                                        ))}
                                    </div>

                                    {/* <h3>{el.title}</h3> */}

                                    <p>
                                        {el.text}
                                    </p>

                                    <h4>{el.author_name}</h4>
                                </div>

                            </div>
                        ))}

                    </Slick>
                </HandleLoading>


                <a href="https://g.page/ClickLeaseGliwice" className={`${s_btns.btn_full} ${s_btns.btn_arrow} ${s.btn}`} target='_blank' rel='noopener noreferer'>Więcej</a>

            </section>
        </>
    )
};

export default OpinionsSection;
